


































import {Component, Prop, Vue} from "vue-property-decorator";
import {OutputDeviceStatusMappingDTO, OutputDeviceTypeDTO} from "@/api";
import Loading from "@/components/Loading.vue";
import StatusMappingList from "@/views/main/admin/StatusMappingList.vue";

@Component({
  components: {StatusMappingList, Loading}
})
export default class DeviceTypeView extends Vue {
  @Prop(Number) readonly deviceTypeId;

  public deviceType: OutputDeviceTypeDTO|null = null;
  public statusMappings: OutputDeviceStatusMappingDTO[]|null = [];

  public async created() {
    this.deviceType = await this.$store.dispatch('deviceTypes/get', { deviceTypeId: this.deviceTypeId });
    this.statusMappings = await this.$store.dispatch('deviceTypes/getStatusMappings', { deviceTypeId: this.deviceTypeId });
  }
}
