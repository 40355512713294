













































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {
  CreateDeviceStatusMappingDTO,
  OutputDeviceStatusMappingDTO
} from '@/api/api';
import DeleteButton from '@/components/DeleteButton.vue';
import { EventBus } from '@/main';
import { updateObjectList } from '@/utils';
import Status, {getStatusSelectItems} from "@/types/Status";
import {AxiosError} from "axios";
import DeviceStatusIcon from "@/components/DeviceStatusIcon.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    Loading,
    DeviceStatusIcon,
    DeleteButton,
  },
})
export default class StatusMappingList extends Vue {
  @Prop(Number) readonly deviceTypeId;

  public headers = [
    {
      text: 'Status Indicator',
      value: 'statusIcon',
      align: 'center',
    },
    {
      text: 'External Status',
      sortable: true,
      align: 'left',
      value: 'external_status',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    },
  ];

  public statusMappings: OutputDeviceStatusMappingDTO[]|null = null;

  public status: string = Status.OK;
  public statusSelectItems: object = getStatusSelectItems();
  public externalStatus: string = '';

  public search: string = '';

  public async created() {
    EventBus.$on('status_mapping', (data) => {
      this.statusMappings = updateObjectList(this.statusMappings, data);
    });
  }

  public async mounted() {
    await this.getStatusMappings();
  }

  public async getStatusMappings() {
    this.statusMappings = await this.$store.dispatch('deviceTypes/getStatusMappings', { deviceTypeId: this.deviceTypeId });
  }

  public async createSubmit() {
    if (await this.$validator.validateAll()) {
      const dto: CreateDeviceStatusMappingDTO = {
        device_type_id: this.deviceTypeId,
        status: this.status,
        external_status: this.externalStatus
      }

      try {
        await this.$store.dispatch('deviceStatusMappings/create', { data: dto });
      } catch (error) {
        console.log('err', error)
        if (error instanceof AxiosError && error.response?.status === 400) {
          this.$store.commit('ui/addErrorNotification', 'External status should be unique for this device type');
          return;
        }

        throw error
      }

      window.location.reload();
    }
  }

  public async removeSubmit(id) {
    await this.$store.dispatch('deviceStatusMappings/delete', { deviceStatusMappingId: id });
    window.location.reload();
  }

  get loading() {
    return this.statusMappings === null;
  }
}
