enum DeviceStatus {
    OK = 'OK',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    UNKNOWN = 'UNKNOWN',
    UNMAPPED = 'UNMAPPED'
}

export default DeviceStatus;

export function getStatusSelectItems() {
    return [
        {
            value: DeviceStatus.OK,
            text: 'Ok'
        },
        {
            value: DeviceStatus.WARNING,
            text: 'Warning'
        },
        {
            value: DeviceStatus.ERROR,
            text: 'Error'
        }
    ]
}
