<template>
  <v-icon v-if="!status" color="grey">help</v-icon>
  <v-icon v-else-if="status === 'ERROR'" color="error">error</v-icon>
  <v-icon v-else-if="status === 'WARNING'" color="warning">warning</v-icon>
  <v-icon v-else-if="status === 'OK'" color="success">check_circle</v-icon>
  <v-icon v-else-if="status === 'UNKNOWN'" color="error">cloud_off</v-icon>
  <v-icon v-else color="grey">help</v-icon>
</template>

<script>
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class DeviceStatusIcon extends Vue {
  @Prop() deviceData;
  @Prop() deviceStatus;
  
  get status() {
    if (this.deviceData) {
      return this.deviceData.status;
    }
    if (this.deviceStatus) {
      return this.deviceStatus;
    }
    
    return null;
  }
}
</script>

<style scoped>

</style>
