export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) => localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');

export const updateObjectList = (objectList, object) => {
    if (object.action === 'create') {
        objectList.push(object.obj);
    } else if (object.action === 'delete') {
        objectList.splice(
            objectList.findIndex((item) => item.id === object.obj.id),
            1,
        );
    } else if (object.action === 'update') {
        objectList.splice(
            objectList.findIndex((item) => item.id === object.obj.id),
            1,
        );
        objectList.push(object.obj);
    }
    return objectList;
}

export const parseApiTimeString = (timeString) => {
    return new Date(timeString);
}

export const formatApiTimeString = (timeString) => {
    const date = parseApiTimeString(timeString);
    const diff = Math.floor((Date.now() - date.getTime()) / 1000);

    let deltaString: string|null = null;
    if (Math.floor(diff) < 60) {
        deltaString = 'A few seconds ago';
    } else if (Math.floor(diff / 60) < 60) {
        if (Math.floor(diff / 60) === 1) {
            deltaString = '1 minute ago';
        } else {
            deltaString = Math.floor(diff / 60) + ' minutes ago';
        }
    } else if (Math.floor(diff / 60 / 60) < 6) {
        if (Math.floor(diff / 60 / 60) === 1) {
            deltaString = '1 hour ago';
        } else {
            deltaString = Math.floor(diff / 60 / 60) + ' hours ago';
        }
    }

    let dateString = date.toLocaleString('en-GB');
    if (deltaString === null) {
        return dateString
    } else {
        return `${deltaString} (${dateString})`
    }
}

function _formatObject(data: object, indentation: number) {
    // Transforms an object into a more readable format

    const indentationStep = 6;
    let res = '';

    const nrKeys = Object.keys(data).length;
    Object.keys(data).forEach((key, i) => {
        const val = data[key];
        res += ' '.repeat(indentation) + key + ': '

        if (Array.isArray(val)) {
            res += '[\n'
            let first = true;

            val.forEach(val => {
                if (first) {
                    first = false;
                } else {
                    res += ',\n\n'
                }

                res += _formatObject(val, indentation + indentationStep)
            })

            res += ' '.repeat(indentation) + '\n]'
        } else if (typeof(val) === 'object') {
            res += '\n';
            res += _formatObject(val, indentation + indentationStep);
            res += '\n'
        } else if (typeof(val) === 'string') {
            res += `"${val.toString()}"`;
        } else {
            res += val.toString();
        }

        if (i !== nrKeys - 1) {
            res += '\n'
        }
    })

    return res;
}

export const formatObject = (data) => {
    return _formatObject(data, 0)
}

export const lowerCaseWord = (text: string) => {
    // Lowercase the entire word except the first letter.
    return text.substr(0, 1).toUpperCase() + text.substr(1).toLowerCase();
}
