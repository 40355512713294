var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Status Mappings")])]),_c('v-card-subtitle',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search...","single-line":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loading)?_c('loading'):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.statusMappings || [],"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.statusIcon",fn:function(ref){
var item = ref.item;
return [_c('device-status-icon',{attrs:{"device-status":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DeleteButton',{attrs:{"itemID":item.id,"deleteFunction":_vm.removeSubmit}})]}}])})],1),_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("New Entry")])]),_c('v-card-text',[[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.statusSelectItems,"label":"Status"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('device-status-icon',{attrs:{"device-status":item.value}}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.text))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('device-status-icon',{attrs:{"device-status":item.value}}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Device Status","required":""},model:{value:(_vm.externalStatus),callback:function ($$v) {_vm.externalStatus=$$v},expression:"externalStatus"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":_vm.createSubmit}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("add")]),_c('span',[_vm._v("Add")])],1)],1)],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }